.my-node-enter {
  opacity: 1;
  background-color: #97c93c;
  color: #173056;
}

.my-node-enter-done {
  background-color: #294063;
  color: white;
  transition: color 2000ms, background-color 2000ms;

}
